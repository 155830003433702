<script lang="ts">
	import type { ComponentData } from '$lib/strapi'
	import { COMPONENT_LIST } from '$lib/strapi/component-list'

	type Component = {
		__typename: string
		[key: string]: any
	}

	type Props = {
		[key: string]: Component | object | string | number | null
	}

	const getComponent = (component: Component) => {
		if (!COMPONENT_LIST.hasOwnProperty(component?.__typename?.toString())) {
			logger.error(
				'dynamicZone',
				`Component mapping for typename ${component?.__typename} doesn't exist in COMPONENT_LIST`,
				component
			)
			return null
		}

		const props: Props = {}
		Object.entries(COMPONENT_LIST[component.__typename].props).forEach(([key, value]) => {
			props[key] = value.split('.').reduce((p, c) => (p && p[c]) || null, component)
		})

		return {
			this: COMPONENT_LIST[component.__typename].this,
			props,
			component
		}
	}

	const getEmptyColumns = (component) => {
		const align = component.align
		const components = component.components.filter((c) => c.width)
		const filledCols = components.reduce((acc, curr) => {
			return acc + curr.width
		}, 0)

		if (filledCols >= 12 || align === 'LEFT') return 0
		if (align === 'CENTER') return Math.floor((12 - filledCols) / 2)
		if (align === 'RIGHT') return Math.floor(12 - filledCols)
		return 0
	}

	export let componentData: ComponentData[]
</script>

<div class="flex w-full flex-col items-center gap-4">
	{#if Array.isArray(componentData) && componentData?.length > 0}
		{#each componentData as component}
			{#if component.components}
				{@const emptyCols = getEmptyColumns(component)}
				<div
					class="{component.mobileOrder === 'reverse'
						? 'flex-col-reverse'
						: 'flex-col'} w-full grid-cols-12 {component.itemsAlign?.replace('_', '-') ||
						'items-start'} gap-4
						{component.hideDesktop && !component.hideMobile && ':md:hidden flex'}
						{component.hideMobile && !component.hideDesktop && 'hidden md:grid'}
						{!component.hideMobile && !component.hideDesktop && 'flex md:grid'}
						{component.hideMobile && component.hideDesktop && 'hidden'}"
					class:max-w-3xl={component.sectionWidth === 'NARROW'}
				>
					{#each Array(emptyCols) as _}
						<div class="hidden md:block" />
					{/each}
					{#each component.components as nestedComponent}
						{#if getComponent(nestedComponent) !== null}
							<svelte:component
								this={getComponent(nestedComponent)?.this}
								{...getComponent(nestedComponent)?.props}
								{component}
							/>
						{/if}
					{/each}
				</div>
			{:else if typeof component === 'object'}
				{#if getComponent(component) !== null && Object.keys(component)?.length > 0}
					<svelte:component
						this={getComponent(component)?.this}
						{...getComponent(component)?.props}
						data={component}
					/>
				{/if}
			{/if}
		{/each}
	{/if}
</div>
